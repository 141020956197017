import { LOGOUT } from 'Reducers/auth/types';

import log from 'Utilities/log';
import {
  clearCookie,
  clearSession,
  getCookie,
  getObject,
  getSessionObject,
  removeObject,
  setCookie,
  setSessionObject,
} from 'Utilities/storage';

import mediator from './mediator';
import { store } from './store';

type Session = Omit<AuthSession, 'include'>;

export const persistAuthSession = (session: Session): void => {
  try {
    setSessionObject('authSession', session);
    setCookie('authSession', session, session.authTicket.expires);

    // remove old session from localStorage
    const storedSession = getObject<Session>('authSession');

    if (storedSession) {
      removeObject('authSession');
    }
  } catch (err) {
    log.error(err);
  }
};

export const getAuthSession = (): Session | null => {
  try {
    // Try to get our token from session storage first or fallback to cookies
    let authSession = getSessionObject<Session>('authSession');

    if (!authSession) {
      authSession = getCookie<Session>('authSession');
    }

    return authSession;
  } catch (e) {
    return null;
  }
};

export const logout = () => {
  clearSession();
  clearCookie('authSession');
  store.dispatch({ type: LOGOUT });
  mediator.publish('router:navigate', '/login');
};

export const removeAuthSession = () => {
  clearSession();
  clearCookie('authSession');
};
