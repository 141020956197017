import React, { useState } from 'react';

import { Divider, LoadingSpinner, Stack, Text, useToast } from '@adc/parallax-component-library';
import { IconValidationValid } from '@adc/parallax-icons';
import { AxiosError } from 'axios';
import { VStack } from 'native-base';

import { ApiStatus } from 'Enums';

import { useApiError, useConnection } from 'Hooks';

import { Link, Main, TitleActionBar } from 'Components/utility';

import i18n from 'Utilities/i18n';
import mediator from 'Utilities/mediator';
import { logout } from 'Utilities/session';

import { removeInvitation, resendInvitation } from 'Services/llu';

type Props = {
  data: { pendingConnectionInvitation: LLUPendingConnectionInvitation };
};

const LLUPendingConnectionDetails: React.FC<Props> = ({
  data: { pendingConnectionInvitation },
}) => {
  const [loading, setLoading] = useState(false);

  const { showToast } = useToast();
  const { showRemoveInvitationConfirmationModal } = useConnection();

  const { showApiErrorModal } = useApiError();

  const onResendInvitation = async () => {
    try {
      setLoading(true);
      await resendInvitation(pendingConnectionInvitation.id);
      showToast({
        message: i18n.t('Connections.modals.invitationSentToast.body'),
        'aria-label': i18n.t('Connections.modals.invitationSentToast.body'),
        icon: <IconValidationValid color="$support.positive.default" />,
      });
    } catch (err) {
      const error = err as AxiosError;

      if (error.status === ApiStatus.UNAUTHORIZED) {
        logout();
        return;
      }

      showApiErrorModal();
    } finally {
      setLoading(false);
    }
  };

  const onRemoveInvitation = async () => {
    showRemoveInvitationConfirmationModal(async () => {
      try {
        setLoading(true);
        await removeInvitation(pendingConnectionInvitation.id);
        showToast({
          message: i18n.t('Global.modals.removeConnection.connectionRemoved'),
          'aria-label': i18n.t('Global.modals.removeConnection.connectionRemoved'),
        });
        mediator.publish('router:navigate', '/connections');
      } catch (err) {
        const error = err as AxiosError;

        if (error.status === ApiStatus.UNAUTHORIZED) {
          logout();
          return;
        }

        showApiErrorModal();
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <Main>
      <TitleActionBar
        testID="PendingConnection"
        title={`${pendingConnectionInvitation.receiver.firstName} ${pendingConnectionInvitation.receiver.lastName}`}
      />
      <Stack
        margin="$4"
        padding="$4"
        backgroundColor="$surface.container"
        border="1.5"
        shadow="$-1"
      >
        <Text
          testID="PendingConnection.title"
          color="$text.100"
          fontWeight="$h3.strong"
          fontSize="$h3.strong"
        >
          {i18n.t('Connections.content.pendingConnection.primaryText')}
        </Text>
        <Text
          testID="PendingConnection.bodyText"
          marginTop="$2"
          color="$text.100"
          fontWeight="$bodyBase.default"
          fontSize="$bodyBase.default"
        >
          {i18n.t('Connections.content.pendingConnection.bodyText')}
        </Text>
        <Text
          testID="PendingConnection.email-label"
          marginTop="$8"
          color="$text.60"
          fontWeight="$bodySmall.default"
          fontSize="$bodySmall.default"
        >
          {i18n.t('Connections.content.pendingConnection.secondaryText')}
        </Text>
        <Text
          testID="PendingConnection.email"
          color="$text.100"
          fontWeight="$bodyBase.medium"
          fontSize="$bodyBase.medium"
        >
          {pendingConnectionInvitation.receiver.email}
        </Text>
        <Divider marginVertical="$4" borderColor="$neutral.40" />
        {loading ? (
          <Stack width="100%" display="flex" alignItems="center">
            <LoadingSpinner />
          </Stack>
        ) : (
          <VStack>
            <Link
              testID="PendingConnection.resendInvitation-btn"
              onPress={onResendInvitation}
              style={{ marginBottom: 32 }}
            >
              {i18n.t<string>('Connections.content.pendingConnection.links.resendInvitation.label')}
            </Link>
            <Link
              testID="PendingConnection.remove-btn"
              onPress={onRemoveInvitation}
              textColor="$support.danger.default"
            >
              {i18n.t<string>('Global.microcopy.common.removeCap')}
            </Link>
          </VStack>
        )}
      </Stack>
    </Main>
  );
};

export default LLUPendingConnectionDetails;
