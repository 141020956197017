import { useMemo } from 'react';

import ForgotPasswordPage from 'Components/auth/ForgotPassword';
import LoginPage from 'Components/auth/Login';
import LLUAddConnection from 'Components/connection/AddConnection/LLUAddConnection';
import LLUConnectionDetails from 'Components/connection/ConnectionDetails/LLUConnectionDetails';
import LLUPendingConnectionDetails from 'Components/connection/ConnectionDetails/LLUPendingConnectionDetails';
import MainConnection from 'Components/connection/MainConnection';
import InitializationErrorPage from 'Components/utility/InitializationErrorPage';

const routes: { route: string; component: React.FC<any>; props?: any }[] = [
  { route: 'login', component: LoginPage },
  { route: 'connections', component: MainConnection },
  { route: 'llu-pending-connection', component: LLUPendingConnectionDetails },
  { route: 'llu-connection', component: LLUConnectionDetails },
  { route: 'llu-add-connection', component: LLUAddConnection },
  { route: 'forgot-password', component: ForgotPasswordPage },
];

const useRouter = (location: string, queryString: string, initErrorStatus?: number, data?: any) => {
  const { Component, props } = useMemo(() => {
    if (initErrorStatus) {
      return {
        Component: InitializationErrorPage,
        props: { errorStatus: initErrorStatus, navigationQueryString: queryString },
      };
    }

    const lowerCaseLocation = location.toLowerCase();

    const route = routes.find((r) => lowerCaseLocation.startsWith(`/${r.route.toLowerCase()}`));

    if (route) {
      return { Component: route.component, props: { ...route.props, data } };
    }

    return { Component: LoginPage };
  }, [data, initErrorStatus, location, queryString]);

  return { Component, props };
};

export default useRouter;
