import jwtDecode from 'jwt-decode';

import { urls } from 'Utilities/dataHelpers';
import * as log from 'Utilities/log';
import mediator from 'Utilities/mediator';

export const signIn = (email: string, password: string, app: string) => {
  return new Promise<{ accessToken: string; accountId: string }>((resolve, reject) => {
    mediator
      .request<ApiResponse<AuthResponse>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'v1/login',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          password,
          consents: [
            {
              id: 'touLibre',
              action: 'accept',
            },
            {
              id: 'pp',
              action: 'accept',
            },
          ],
        },
      })
      .then((result) => {
        if (result.data) {
          const decodedToken = jwtDecode<{ id: string; did: string; exp: number; iat: number }>(
            result.data.access_token
          );

          return resolve({ accessToken: result.data.access_token, accountId: decodedToken.id });
        }

        reject(result);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const forgotPass = (email: string, app: string, pId: string) => {
  return new Promise<void>((resolve, reject) => {
    mediator
      .request<ApiResponse<void>>('api:invoke', {
        method: 'POST',
        url: urls.libreSharingApiUrl + 'v1/password/forgot',
        headers: {
          'X-User-Agent': app,
        },
        data: {
          email,
          partnerID: pId,
        },
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const getLegacyToken = (app: string, accountId: string, accessToken: string) => {
  return new Promise<string>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'GET',
        url: urls.libreSharingApiUrl + `v1/patients/${accountId}/legacy-sharing-webviews-token`,
        headers: {
          'X-User-Agent': app,
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((result) => {
        if (result.data) {
          resolve(result.data.tokenId);
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};

export const getLegacyAuthSession = (legacyToken: string) => {
  return new Promise<AuthSession>((resolve, reject) => {
    mediator
      .request<ApiResponse>('api:invoke', {
        method: 'GET',
        url: urls.lvapiURL + `sharing/token/${legacyToken}`,
        headers: {
          Authorization: `Bearer ${legacyToken}`,
        },
      })
      .then((result) => {
        if (result.data?.status === 0) {
          const { data } = result.data;

          const authSession: AuthSession = {
            user: data.user,
            authTicket: data.authTicket,
          };

          resolve(authSession);
          return;
        }

        reject();
      })
      .catch((err) => {
        log.error(err);
        reject(err);
      });
  });
};
